import { extendTheme } from '@mui/joy';

const theme = extendTheme({
  fontFamily: {
    display: "TheSeasons", // applies to `h1`–`h4`
    body: "Times New Roman", // applies to `title-*` and `body-*`
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: "#efebe9",
          100: "#d7ccc8",
          200: "#bcaaa4",
          300: "#a1887f",
          400: "#8d6e63",
          500: "#795548",
          600: "#6d4c41",
          700: "#5d4037",
          800: "#4e342e",
          900: "#3e2723"
        },
        neutral: {
          50: "#efebe9",
          100: "#d7ccc8",
          200: "#bcaaa4",
          300: "#a1887f",
          400: "#8d6e63",
          500: "#795548",
          600: "#6d4c41",
          700: "#5d4037",
          800: "#4e342e",
          900: "#3e2723"
        }
      },
      border: {
        default: '#e0e0e0', // Define a default border color
      },
    },
    dark: {
      palette: {},
      border: {
        default: '#4e342e', // Define a default border color for dark mode
      },
    },
  },
});

export default theme;