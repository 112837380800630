import React from "react";
import { Typography, Button } from "@mui/joy";
import Checkmark from "../components/Checkmark";
import { useNavigate } from "react-router-dom";
import RSVPResponses from "./RSVPResponses";
import { StyledSheet } from "./RSVPInputPage";

const SubmittedPage = ({ code }) => {
  const navigate = useNavigate();
  return (
    <StyledSheet>
      <Checkmark />
      <Typography level="h1" textAlign={"center"}>
        We have received your RSVP!
      </Typography>
      <Typography level="h2" gutterBottom={false} textAlign={"center"}>
        Whether you are coming in person or in spirit, we are so exited to
        celebrate with you!
      </Typography>
      <Typography level="h4" textAlign={"center"} marginTop={"20px"}>
        If you need to make any changes to your RSVP please contact us at{" "}
        <a
          href="mailto:kunalandforum@gmail.com?subject=Wedding RSVP Question"
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          kunalandforum@gmail.com
        </a>
      </Typography>
      <Button
        onClick={() => {
          navigate("/");
        }}
      >
        Return to homepage
      </Button>

      <RSVPResponses code={code} />
    </StyledSheet>
  );
};

export default SubmittedPage;
