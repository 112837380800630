import { useQuery } from '@tanstack/react-query';

const useGetInvitees = (code) => {
    const fetchInvitees = async () => {
        const response = await fetch(`/api/rsvp/${code}`);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to fetch RSVP info');
        }
        const data = await response.json();
        return data?.data;
    };

    return useQuery({
        queryKey: ['invitees', code],
        queryFn: fetchInvitees,
        enabled: !!code,
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false,
        retry: false
    });
};

export default useGetInvitees;