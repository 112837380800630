import React, { useState } from "react";
import { ChevronDown } from "lucide-react";
import styled from "styled-components";
import { Typography, List, ListItem, Link } from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { Section } from "./Section";

const FAQList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FAQItem = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(99, 72, 78, 0.1);
`;

const QuestionButton = styled.button`
  width: 100%;
  padding: 1.25rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  transition: all 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  background: ${(props) => (props.isOpen ? "rgba(99, 72, 78, 0.05)" : "none")};

  &:hover {
    background-color: rgba(99, 72, 78, 0.05);
  }

  &:focus {
    outline: none;
  }

  svg {
    color: #63484e;
    transition: transform 0.3s ease;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
  }
`;

const Question = styled.h3`
  font-family: "TheSeasons", serif;
  font-size: 1.125rem;
  color: #34111a;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const AnswerContainer = styled.div`
  padding: ${(props) =>
    props.isOpen ? "0.5rem 1.25rem 0.5rem 1.5rem" : "0 1.5rem"};
  max-height: ${(props) => (props.isOpen ? "26rem" : "0")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  overflow: hidden;
  transition: all 0.5s ease-in-out;
`;

const Answer = styled.p`
  color: rgba(52, 17, 26, 0.8);
  font-family: "Times New Roman", serif;
`;
const FAQ = ({ hotelSectionRef }) => {
  const navigate = useNavigate();

  const scrollToHotelSection = () => {
    hotelSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const faqItems = [
    {
      question: "How do I RSVP?",
      answer: (
        <>
          <Typography gutterBottom>
            We request you RSVP on our website
          </Typography>
          <List marker={"decimal"}>
            <ListItem>
              <Typography gutterBottom>
                Navigate to our RSVP page{" "}
                <Link
                  onClick={() => {
                    navigate("/rsvp");
                  }}
                  underline="always"
                >
                  <strong>kunalandforum.com/rsvp</strong>
                </Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography gutterBottom>
                Enter the <strong>RSVP code</strong> provided on the last page
                of your PDF invite, submit your response, and you're all set!
              </Typography>
            </ListItem>
            <ListItem>
              <Typography gutterBottom>
                Submit your responses, and you're all set!
              </Typography>
            </ListItem>
          </List>
          <Typography gutterBottom>
            If you have any issues, feel free to email us at{" "}
            <strong>example@gmail.com</strong>.
          </Typography>
        </>
      ),
    },
    {
      question: "What should I wear?",
      answer: (
        <>
          <Typography gutterBottom>
            We would love for our guests to wear{" "}
            <strong>Indian formal attire</strong> for the wedding ceremony. This
            could include <em>sarees, lehengas</em>, etc., for women, and{" "}
            <em>sherwanis, kurtas, or suits</em> for men.
          </Typography>
          <Typography gutterBottom>
            If you don't have Indian attire, a formal outfit in{" "}
            <strong>vibrant colors</strong> is also welcome! Let us know if
            you'd like recommendations on where to find Indian clothing.
          </Typography>
          <Typography gutterBottom>
            We can't wait to celebrate with you in style!
          </Typography>
        </>
      ),
    },
    {
      question: "What if it rains?",
      answer: (
        <Typography gutterBottom>
          No worries at all—<strong>all events will be indoors</strong>! Rain or
          shine, we're going to party and celebrate to the fullest. So bring
          your best dance moves and leave the weather concerns behind!
        </Typography>
      ),
    },
    {
      question: "What if I have dietary restrictions?",
      answer: (
        <>
          <Typography gutterBottom>
            <strong>All food at all events will be vegetarian</strong>, without
            onions, garlic, or eggs.
          </Typography>
          <Typography gutterBottom>
            If you have any specific dietary restrictions, please let us know,
            and we will do our best to accommodate.
          </Typography>
        </>
      ),
    },
    {
      question: "Will there be parking?",
      answer: (
        <Typography gutterBottom>
          Yes, there will be <strong>free parking onsite</strong> at the venue.
        </Typography>
      ),
    },
    {
      question: "What airport should I fly into?",
      answer: (
        <Typography gutterBottom>
          We recommend flying into{" "}
          <strong>Toronto Pearson International Airport (YYZ)</strong>, which is
          the closest to the venue.
        </Typography>
      ),
    },

    {
      question: "Where should I stay?",
      answer: (
        <>
          <Typography gutterBottom>
            We have arranged a discounted group booking rate, please see the{" "}
            <Link onClick={() => scrollToHotelSection()} underline="always">
              <strong>Hotel Section</strong>
            </Link>{" "}
            above for more details.
          </Typography>
          <Typography gutterBottom>
            There are also plenty of other accommodation options in the area if
            you prefer something different. Let us know if you need any other
            recommendations!
          </Typography>
        </>
      ),
    },
    {
      question: "Is there a gifts registry?",
      answer: (
        <Typography gutterBottom>
          We do not have a gifts registry. We kindly ask for{" "}
          <strong>no boxed gifts</strong> at the function.
        </Typography>
      ),
    },
    {
      question: "Can I bring a +1 ?",
      answer: (
        <>
          <Typography gutterBottom>
            The invite card has the list of invitees, but if there is someone
            you wish to bring, please reach out to us and we will try our best
            to accommodate.
          </Typography>
          <Typography gutterBottom>
            We appreciate your understanding!
          </Typography>
        </>
      ),
    },
    {
      question: "What can I do in Toronto?",
      answer: (
        <>
          <Typography gutterBottom>
            Toronto has plenty to explore! Here are some top recommendations:
          </Typography>
          <List marker={"disc"}>
            <ListItem>
              <Typography>
                <strong>Visit the CN Tower & Ripley's Aquarium</strong> – Take
                in stunning views of the city from the CN Tower's observation
                deck, then explore Ripley's Aquarium to see marine life up
                close.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Explore the Royal Ontario Museum</strong> – Discover
                art, culture, and natural history at one of the world's leading
                museums.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Walk through High Park</strong> – Enjoy nature trails,
                gardens, and the beautiful Grenadier Pond in this expansive
                park.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Stroll around Kensington Market</strong> – Experience
                the vibrant, multicultural neighborhood with unique shops,
                street art, and diverse food.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Visit the Toronto Islands</strong> – Take a ferry to the
                islands, where you can enjoy beaches, picnic areas, bike
                rentals, and amazing city skyline views.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography>
                <strong>Have fun at Canada's Wonderland</strong> – Thrill
                seekers will love Canada’s largest amusement park, featuring
                roller coasters, water rides, and live entertainment.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography gutterBottom>
                <strong>Take a day trip to Niagara Falls</strong> – Just a short
                drive away, marvel at the stunning falls and enjoy boat tours,
                hiking, and local attractions.
              </Typography>
            </ListItem>
          </List>
        </>
      ),
    },
  ];

  const [openItems, setOpenItems] = useState([]);

  const toggleItem = (index) => {
    setOpenItems((prev) =>
      prev.includes(index)
        ? prev.filter((item) => item !== index)
        : [...prev, index]
    );
  };

  return (
    // <Section id="faq">
    //   <Container>
    //     <HeaderContainer>
    //       <Title>Frequently Asked Questions</Title>
    //       <Divider />
    //     </HeaderContainer>
    <Section sectionTitle={"Frequently Asked Questions"}>
      <FAQList>
        {faqItems.map((item, index) => (
          <FAQItem key={index}>
            <QuestionButton
              onClick={() => toggleItem(index)}
              isOpen={openItems.includes(index)}
            >
              <Question>{item.question}</Question>
              <ChevronDown size={20} />
            </QuestionButton>

            <AnswerContainer isOpen={openItems.includes(index)}>
              <Answer>{item.answer}</Answer>
            </AnswerContainer>
          </FAQItem>
        ))}
      </FAQList>
    </Section>
    //   </Container>
    // </Section>
  );
};

export default FAQ;
