import { useQuery } from '@tanstack/react-query';

const useGetResponse = (code) => {
    const fetchResponse = async () => {
        const response = await fetch(`/api/response/${code}`);
        if (!response.ok) {
            throw new Error('Failed to fetch RSVP info');
        }
        const data = await response.json();
        return data?.data;
    };

    return useQuery({
        queryKey: ['response', code],
        queryFn: fetchResponse,
        enabled: !!code,
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnWindowFocus: false
    });
};

export default useGetResponse;