import React from 'react';
import { Stack, Divider, Typography } from '@mui/joy'

const RSVPStatus = ({ invitee, idx, lastId, response }) => {

    return (
        <>
            <Stack key={idx} direction={{ xs: "column", sm: "row" }} justifyContent={"space-between"} spacing={1} alignItems={{ xs: "center", sm: "center" }}>
                <Typography level='h4'>{invitee.name}</Typography>
                {invitee.response ?
                    <Typography>Joyfully Attending</Typography> :
                    <Typography>Regretfully Decline</Typography>
                }

            </Stack>
            {idx !== lastId - 1 && <Divider />}
        </>
    );
};

export default RSVPStatus;