import React from 'react';
import { Stack, Radio, RadioGroup, Divider, Typography, FormHelperText, FormControl } from '@mui/joy'
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const RSVPStatusSelector = ({ invitee, idx, lastId, response, handleChange, eventName, isError }) => {

    const currentResponse = response.find(r => r.InviteeID === invitee.InviteeID);

    return (
        <>
            <Stack key={idx} direction={{ xs: "column", sm: "row" }} justifyContent={"space-between"} spacing={1} alignItems={{ xs: "center", sm: "center" }}>
                <Typography level='h4'>{invitee.InviteeName}</Typography>
                <Stack key={idx} direction="column" justifyContent={"center"} spacing={1} >
                    <FormControl error={isError}>
                        <RadioGroup
                            name={`radio-${invitee.InviteeID}-${eventName}`}
                            orientation="vertical"
                            value={currentResponse[eventName]}
                            onChange={(e) => handleChange(invitee.InviteeID, eventName, e.target.value === 'true')}
                        >
                            <Radio value={true} label={"Joyfully Attending"} variant="outlined" />
                            <Radio value={false} label="Regretfully Decline" variant="outlined" />
                        </RadioGroup>
                        {(isError && currentResponse[eventName] == null) &&
                            <FormHelperText >
                                <InfoOutlined />
                                <Typography level='body-p' textAlign="right"> Please provide a response </Typography>
                            </FormHelperText>}
                    </FormControl >

                </Stack>
            </Stack>
            {idx !== lastId - 1 && <Divider />}
        </>
    );
};

export default RSVPStatusSelector;