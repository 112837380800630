import { useMutation } from '@tanstack/react-query';

const postRsvp = async (rsvpData) => {
    console.log('rsvpData:', rsvpData);
  const response = await fetch('/api/rsvp', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({...rsvpData, ResponseTime: new Date().toISOString()}),
  });

  if (!response.ok) {
    throw new Error('Failed to submit RSVP');
  }

  return response.json();
};

const usePostRsvp = () => {
  return useMutation({
    mutationFn: postRsvp,
    onError: (error) => {
      console.error('Mutation error:', error);
    }
  });
};

export default usePostRsvp;