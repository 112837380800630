import React, { forwardRef } from "react";
import { Section } from "./Section";
import { Stack, Typography, Button, Link } from "@mui/joy";

const HotelInfoSection = forwardRef((props, ref) => {
  return (
    <Section
      ref={ref}
      sectionTitle="Hotel Group Booking"
      backgroundColor={"#63484E"}
      titleColor={"#F0ECE8"}
    >
      <Stack spacing={3} sx={{ margin: "0 auto", textAlign: "center" }}>
        <Typography
          level="body-lg"
          sx={{ color: "#F0ECE8", fontSize: "1.25rem" }}
        >
          We’re excited to welcome you to our wedding and have made arrangements
          to make your stay as convenient as possible!
        </Typography>
        <Typography
          level="body-lg"
          sx={{ color: "#F0ECE8", fontSize: "1.25rem" }}
        >
          We have reserved a hotel block at{" "}
          <Link
            href="https://maps.app.goo.gl/p3FYtqchrXQP3Vca7"
            underline="always"
            target="_blank"
            sx={{ color: "#F0ECE8", textDecorationColor: "#F0ECE8" }}
          >
            <strong>Tru by Hilton Toronto Airport West</strong>
          </Link>{" "}
          with a <strong>special discounted rate</strong>.
        </Typography>
        <Typography
          level="body-lg"
          sx={{ color: "#F0ECE8", fontSize: "1.25rem" }}
        >
          To take advantage of this special rate, please book by{" "}
          <strong>June 15</strong> <br />
          Rooms are limited – so we recommend booking early!
        </Typography>
        <Button
          variant="solid"
          color="primary"
          size="lg"
          component="a"
          href="https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=YTOPCRU&arrivalDate=2025-08-29&departureDate=2025-09-01&groupCode=KUNFO&room1NumAdults=1"
          target="_blank"
          sx={{
            backgroundColor: "#F0ECE8",
            color: "#63484E",
            fontWeight: "bold",
            fontSize: "1.1rem",
            padding: "1rem 2rem",
            maxWidth: "200px",
            alignSelf: "center",
            "&:hover": {
              backgroundColor: "#63484E",
              color: "#F0ECE8",
              outline: "2px solid #F0ECE8",
            },
          }}
        >
          Book Your Stay
        </Button>
        <Typography
          level="body-lg"
          sx={{ color: "#F0ECE8", fontSize: "1.25rem" }}
        >
          If you prefer a different stay, there are plenty of other
          accommodation options nearby to choose from. <br />
          Let us know if you need help or additional recommendations!
        </Typography>
      </Stack>
    </Section>
  );
});
export default HotelInfoSection;
