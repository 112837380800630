import { useRef } from "react";
import "react-photo-album/rows.css";
import { HeroBanner } from "./components/HeroBanner/HeroBanner";
import { ProfileSection } from "./components/ProfileSection/ProfileSection";
import { OurStorySection } from "./components/OurSectionSection/OurStorySection";
import FAQSection from "./components/FAQ";
import HotelInfoSection from "./components/HotelInfoSection";
import { Footer } from "./components/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RSVPLandingPage from "./pages/LandingPage/RSVPPage";
// import  RSVPLandingPage  from "./pages/LandingPage/RSVPLandingPage";
import theme from "./theme/theme";
import { CssVarsProvider } from "@mui/joy";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const Home = () => {
  const hotelSectionRef = useRef(null);

  return (
    <>
      <HeroBanner />
      <ProfileSection />
      <OurStorySection />
      <HotelInfoSection ref={hotelSectionRef} />
      <FAQSection hotelSectionRef={hotelSectionRef} />
      <Footer />
    </>
  );
};

export default function App() {
  return (
    <CssVarsProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/rsvp" element={<RSVPLandingPage />} />
            {/* 404 fallback route */}
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </CssVarsProvider>
  );
}
