import { styled } from '@mui/joy';

export const StyledSheet = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main, // Accessing primary color directly
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(4), // Using spacing function
    margin: theme.spacing(3), // Using spacing function
    height: '100%',
    borderRadius: 30,
    // backgroundColor: '#FCFAF8',
    padding: theme.spacing(1), // Using spacing function
}));