import React from "react";
import { Box, Typography, Card, Stack } from "@mui/joy";
import useGetResponse from "../../api/useGetResponse";
import RSVPStatus from "../components/RSVPStatus";
import { StyledSheet } from "./RSVPInputPage";

const eventNames = {
  ForumHomeEvent: {
    name: "Haldi & Mehndi",
    date: "August 28th 5:00PM",
    locationName: "- Trivedi Residence",
    location: "10 Markey Ct, Brampton Ontario L6P 4L1",
  },
  Sangeet: {
    name: "Sangeet",
    date: "August 29th 6:00PM",
    locationName: " - Red Rose Convention Center",
    location: "1233 Derry Rd E, Mississauga, Ontario L5T 1B6",
  },
  KunalHomeEvent: {
    name: "Pre-Wedding Ceremonies",
    date: "August 30th 8:00AM",
    locationName: "- Sagan Banquet Hall",
    location: "7180 Edwards Blvd. Mississauga, Ontario L5S 1Z1",
  },
  Wedding: {
    name: "Wedding",
    date: "August 31 8:00AM",
    locationName: " - Red Rose Convention Center",
    location: "1233 Derry Rd E, Mississauga, Ontario L5T 1B6",
  },
  Reception: {
    name: "Reception",
    date: "August 31 6:30PM",
    locationName: " - Red Rose Convention Center",
    location: "1233 Derry Rd E, Mississauga, Ontario L5T 1B6",
  },
};

const groupByEvent = (data) => {
  const events = [
    "ForumHomeEvent",
    "Sangeet",
    "KunalHomeEvent",
    "Wedding",
    "Reception",
  ];
  const groupedData = {};

  events.forEach((event) => {
    const filteredInvitees = data
      .filter((invitee) => invitee[event] !== undefined)
      .map((invitee) => ({
        name: invitee.name,
        id: invitee.id,
        response: !!invitee[event],
      }));

    if (filteredInvitees.length > 0) {
      groupedData[event] = filteredInvitees;
    }
  });

  return groupedData;
};

const RSVPResponses = ({ code }) => {
  const { data, isLoading } = useGetResponse(code);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const groupedData = groupByEvent(data);

  return (
    <StyledSheet>
      {Object.keys(groupedData).map((eventName, index) => (
        <Card key={index} variant="plain" size="lg" sx={{ width: "100%" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            marginBottom="15px"
          >
            <Typography level="h2" margin={"10px"}>
              {eventNames[eventName].name}
            </Typography>

            <Typography level="body-lg" marginX={"10px"}>
              <Typography sx={{ fontWeight: "800" }}>
                {" "}
                {eventNames[eventName].date}{" "}
              </Typography>
              {eventNames[eventName].locationName}
            </Typography>

            <Typography level="body-md" marginX={"10px"}>
              {eventNames[eventName].location}
            </Typography>
          </Box>
          <Stack direction="column" spacing={2}>
            {groupedData[eventName].map((invitee, idx) => (
              <RSVPStatus
                key={idx}
                invitee={invitee}
                idx={idx}
                lastId={groupedData[eventName].length}
              />
            ))}
          </Stack>
        </Card>
      ))}
    </StyledSheet>
  );
};

export default RSVPResponses;
