import React, { useState } from "react";
import {
  Input,
  Stack,
  Button,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/joy";
import { Sheet } from "../components/Sheet";
import useGetInvitees from "../../api/useGetInvitees";
import styled from "styled-components";
import rsvpText from "../../assets/rsvpText.svg";
import RSVPInputPage from "./RSVPInputPage";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useLocation } from "react-router-dom";

const CenteredWrapperInput = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -160px;
`;

const RSVPLandingPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryCode = searchParams.get("code") || ""; // Get `code` from URL query params

  const [submittedCode, setSubmittedCode] = useState("");
  const [code, setCode] = useState(queryCode.toUpperCase().slice(0, 6)); // Initialize with query param

  const { data, isLoading, isError, error } = useGetInvitees(submittedCode);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedCode(code);
  };

  return (
    <Sheet>
      {!data && (
        <>
          <CenteredWrapperInput>
            <img src={rsvpText} alt="RsvpText" />
          </CenteredWrapperInput>
          <Typography level="h4">Enter your unique R.S.V.P. Code</Typography>
          <form onSubmit={handleSubmit}>
            <FormControl error={isError}>
              <Stack direction="row" spacing={2}>
                <Input
                  placeholder="RSVP Code"
                  value={code}
                  onChange={(e) => {
                    const upperValue = e.target.value.toUpperCase();
                    // const lettersOnly = upperValue.replace(/[^A-Z]/g, '');
                    setCode(upperValue.slice(0, 6)); // Enforce 6 character limit
                  }}
                />
                <Button type="submit" loading={isLoading}>
                  Submit
                </Button>
              </Stack>
              {isError && error && (
                <FormHelperText>
                  <InfoOutlined />
                  <Typography level="body-p" textAlign="right">
                    {" "}
                    {error.message}{" "}
                  </Typography>
                </FormHelperText>
              )}
            </FormControl>
          </form>
        </>
      )}
      {data && <RSVPInputPage data={data} />}
    </Sheet>
  );
};

export default RSVPLandingPage;
