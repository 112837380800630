import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Card,
  FormHelperText,
  FormControl,
  Input,
} from "@mui/joy";
import RSVPStatusSelector from "../components/RSVPStatusSelector";
import usePostRsvp from "../../api/usePostRsvp";
import RSVPSubmittedPage from "./RSVPSubmittedPage";
import rsvpText from "../../assets/rsvpText.svg";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { styled } from "@mui/joy";

export const StyledSheet = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(4),
  height: "100%",
  width: "fit-content",
}));

const eventNames = {
  ForumHomeEvent: {
    name: "Haldi & Mehndi",
    date: "Thursday, August 28th 5:00PM",
    locationName: "Trivedi Residence",
    location: "10 Markey Ct, Brampton Ontario L6P 4L1",
  },
  Sangeet: {
    name: "Sangeet",
    date: "Friday, August 29th 6:00PM",
    locationName: "Red Rose Convention Center",
    location: "1233 Derry Rd E, Mississauga, Ontario L5T 1B6",
  },
  KunalHomeEvent: {
    name: "Pre-Wedding Ceremonies",
    date: "Saturday, August 30th 8:00AM",
    locationName: "Sagan Banquet Hall",
    location: "7180 Edwards Blvd. Mississauga, Ontario L5S 1Z1",
  },
  Wedding: {
    name: "Wedding",
    date: "Sunday, August 31 8:00AM",
    locationName: "Red Rose Convention Center",
    location: "1233 Derry Rd E, Mississauga, Ontario L5T 1B6",
  },
  Reception: {
    name: "Reception",
    date: "Sunday, August 31 6:30PM",
    locationName: "Red Rose Convention Center",
    location: "1233 Derry Rd E, Mississauga, Ontario L5T 1B6",
  },
};

const groupByEvent = (data) => {
  const events = [
    "ForumHomeEvent",
    "Sangeet",
    "KunalHomeEvent",
    "Wedding",
    "Reception",
  ];
  const groupedData = {};

  events.forEach((event) => {
    const filteredInvitees = data
      .filter((invitee) => invitee[event] === 1)
      .map((invitee) => ({
        InviteeName: invitee.InviteeName,
        InviteeID: invitee.InviteeID,
        HasRSVP: invitee.HasRSVP,
      }));

    if (filteredInvitees.length > 0) {
      groupedData[event] = filteredInvitees;
    }
  });

  return groupedData;
};
const transformInviteeData = (data) => {
  return data.map((invitee) => ({
    InviteeName: invitee.InviteeName,
    InviteeID: invitee.InviteeID,
    ForumHomeEvent: invitee.ForumHomeEvent === 1 ? null : false,
    Sangeet: invitee.Sangeet === 1 ? null : false,
    KunalHomeEvent: invitee.KunalHomeEvent === 1 ? null : false,
    Wedding: invitee.Wedding === 1 ? null : false,
    Reception: invitee.Reception === 1 ? null : false,
  }));
};

const isNotValidEmail = (email) => {
  if (!email) {
    return true;
  }
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return !pattern.test(email);
};

const validateResponse = (response, email) => {
  return (
    response.every((invitee) => {
      return (
        invitee.ForumHomeEvent !== null &&
        invitee.Sangeet !== null &&
        invitee.KunalHomeEvent !== null &&
        invitee.Wedding !== null &&
        invitee.Reception !== null
      );
    }) && !isNotValidEmail(email)
  );
};

const hasSubmitted = (data) => {
  return data.filter((invitee) => invitee.HasRSVP === 1).length > 0;
};

const RSVPInputPage = ({ data }) => {
  const { mutate, isLoading } = usePostRsvp();

  const [response, setResponse] = useState(transformInviteeData(data));

  const [email, setEmail] = useState("");

  const [isError, setIsError] = useState(false);

  const [submitted, setSubmitted] = useState(hasSubmitted(data));

  const groupedData = useMemo(() => groupByEvent(data), [data]);

  const handleChange = (inviteeId, event, value) => {
    setResponse((prevResponse) => {
      const newResponse = prevResponse.map((invitee) =>
        invitee.InviteeID === inviteeId
          ? { ...invitee, [event]: value }
          : invitee
      );
      if (isError) {
        const isValid = validateResponse(newResponse, email);
        setIsError(!isValid);
      }
      return newResponse;
    });
  };

  const handleSubmit = () => {
    const isValid = validateResponse(response, email);
    setIsError(!isValid);

    if (!isValid) {
      return;
    }

    const submitData = {
      RSVPCode: data[0].RSVPCode,
      rsvps: response,
      email: email,
    };

    mutate(submitData, {
      onSuccess: () => {
        setSubmitted(true);
        console.log("RSVP submitted successfully");
      },
      onError: (error) => {
        console.error("Error submitting RSVP:", error);
      },
    });
  };

  if (submitted) {
    return <RSVPSubmittedPage code={data[0].RSVPCode} />;
  }

  return (
    <>
      <Box>
        <img src={rsvpText} alt="RsvpText" />
      </Box>
      <StyledSheet>
        <Card variant="plain" size="lg" sx={{ width: "100%" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            marginBottom="15px"
          >
            <Typography level="h2" margin={"10px"}>
              Email
            </Typography>

            <Typography
              level="body-lg"
              marginX={"10px"}
              sx={{
                display: "inline-flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "0.25rem", // Space between items
              }}
            >
              <span>Please enter a contact email for your family</span>
            </Typography>
            <FormControl
              error={isError && isNotValidEmail(email)}
              sx={{ width: "100%" }}
            >
              <Input
                placeholder="Contact email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (isError) {
                    const isValid = validateResponse(response, email);
                    setIsError(!isValid);
                  }
                }}
                sx={{
                  width: "100%",
                  margin: "10px 10px 0 10px",
                }}
              />

              {isError && isNotValidEmail(email) && (
                <FormHelperText>
                  <InfoOutlined />
                  <Typography level="body-p" textAlign="right">
                    {" "}
                    Please provide a valid email
                  </Typography>
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Card>
        {Object.keys(groupedData).map((eventName, index) => (
          <Card key={index} variant="plain" size="lg" sx={{ width: "100%" }}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              marginBottom="15px"
            >
              <Typography level="h2" margin={"10px"}>
                {eventNames[eventName].name}
              </Typography>

              <Typography
                level="body-lg"
                marginX={"10px"}
                sx={{
                  display: "inline-flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "0.25rem", // Space between items
                  "::after": {
                    content: '"-"',
                    order: 2, // Ensures the dash appears between spans
                  },
                  "span:last-child": {
                    order: 3, // Moves last span after the dash
                  },
                  "span:first-child": {
                    order: 1, // Ensures first span is before the dash
                  },
                  "@media (max-width: 567px)": {
                    // Adjust breakpoint as needed
                    "::after": {
                      content: '""',
                      order: 2, // Ensures the dash appears between spans
                    }, // Hide dash when wrapped
                  },
                }}
              >
                <span style={{ fontWeight: "800" }}>
                  {eventNames[eventName].date}
                </span>
                <span style={{ whiteSpace: "nowrap" }}>
                  {eventNames[eventName].locationName}
                </span>
              </Typography>

              <Typography level="body-md" marginX={"10px"}>
                {eventNames[eventName].location}
              </Typography>
            </Box>
            <Stack direction="column" spacing={2}>
              {groupedData[eventName].map((invitee, idx) => (
                <RSVPStatusSelector
                  key={idx}
                  invitee={invitee}
                  idx={idx}
                  lastId={groupedData[eventName].length}
                  response={response}
                  handleChange={handleChange}
                  eventName={eventName}
                  isError={isError}
                />
              ))}
            </Stack>
          </Card>
        ))}
        <FormControl error={isError}>
          <Button
            fullWidth={false}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isError}
            size="md"
            sx={{ width: "fit-content", margin: "0 auto" }}
          >
            Submit
          </Button>

          {isError && (
            <FormHelperText sx={{ marginBottom: "10px" }}>
              <InfoOutlined />
              <Typography level="body-p" textAlign={"center"}>
                Please provide a response for all inputs
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
      </StyledSheet>
    </>
  );
};

export default RSVPInputPage;
